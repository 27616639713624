'use strict';

angular.module('cpformplastApp.inventory')
    .controller('rawMaterialController', function($scope, DataManager, $state, Util, Notification) {

    $scope.rawMaterialsWithJob = [];
    $scope.rawMaterialsWithoutJob = [];

    $scope.tableButtons = [{
      'icon':'print',
      'title':'Imprimer',
      'function': function() {
        $scope.printContent();
      }
    }];

    $scope.tableStructureWithJob = [{
      'title':'Dimensions et Catégorie',
      'value':'dimcat',
      'key':'dimcat',
      'width': 350,
      'filter':'alpha'
    },{
      'title':'Job',
      'value':'job',
      'key':'job',
      'width': 150,
      'filter':'alpha'
    },{
      'title':'Client',
      'value':'client',
      'key':'client',
      'width': 350,
      'filter':'alpha'
    },{
      'title':'Quantité (lbs)',
      'value':'quantity',
      'key':'quantity',
      'width': 150,
      'filter':'num'
    },{
      'title':'Quantité (palettes)',
      'value':'quantityInSkid',
      'key':'quantityInSkid',
      'width': 150,
      'filter':'num'
    }];

      $scope.tableStructureWithoutJob = [{
        'title':'Dimensions et Catégorie',
        'value':'dimcat',
        'filter':'alpha'
      },{
        'title':'Quantité (lbs)',
        'value':'quantity',
        'filter':'num'
      },{
        'title':'Quantité (palettes)',
        'value':'quantityInSkid',
        'filter':'num'
      }];

    $scope.init = function() {
      DataManager.fetchGroupedRawMaterials().then((data) => {
        for (var i=0 ; i< data.length ; i++) {
          if (data[i].job) {
            rawMaterial = {
              'dimcat': data[i].category.dimension + ' ' + data[i].category.name,
              'job': data[i].job.number,
              'client': data[i].job.item.client.name,
              'quantity': data[i].quantity,
              'quantityInSkid': data[i].skid
            };
            $scope.rawMaterialsWithJob.push(rawMaterial);
          }else{
            rawMaterial = {
              'dimcat': data[i].category.dimension + ' ' + data[i].category.name,
              'quantity': data[i].quantity,
              'quantityInSkid': data[i].skid
            };
            $scope.rawMaterialsWithoutJob.push(rawMaterial);
          }

        }
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu lors du chargement des livraisons');
      });
    };

    $scope.selectRawMaterial= function({ job, dimcat }) {
      if (job){
        $state.go('inventory/grid', {'search': job, 'filter':'raw-material' });
      } else {
        $state.go('inventory/grid', {'search': dimcat, 'filter':'raw-material' });
      }
    };

    $scope.printContent = function() {
      let title = "Matières premières (" + Util.getDateTimeString(new Date()) + ")";
      let columns = $scope.tableStructureWithJob;
      var sections = [{
        title: 'Avec job',
        elements: $scope.rawMaterialsWithJob
      },{
        title: 'Sans job',
        elements: $scope.rawMaterialsWithoutJob
      }];

      let tableStructure = {
        title: title,
        columns: columns,
        sections: sections
      };

      DataManager.downloadPdfTable(tableStructure, "matieres-premieres");
    };

    $scope.init();
});
